import React from 'react';

const ComingSoon = () => {
    return (
        <section className="p-10 min-h-screen flex md:flex-row items-center justify-around bg-white">
            <h1 className='font-manrope font-extrabold text-2xl md:text-9xl text-gradient'>Coming Soon</h1>
        </section>
    )
}

export default ComingSoon;