import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';

const Pricing = () => {
    return (
        <Layout>

            <section className="bg-white">
                <div className="max-w-7xl px-5 md:px-0 py-10 md:py-20 mx-auto">
                    <h1 className="font-extrabold text-3xl md:text-5xl text-gradient max-w-2xl">Pricing that suits your business. Start building today!</h1>
                    <p className="mt-4 text-[#373737] xl:mt-6 max-w-md text-base md:text-lg">Scale your  enterprise, startup, E-commernce, or Metaverse NFT business. Get started for free.</p>
                    <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-20 md:grid-cols-2 xl:grid-cols-2">
                        <div className="p-10 md:p-20 space-y-10 border rounded-[25.3008px] bg-gradient text-white">
                            <h3 className="text-xl md:text-2xl font-semibold">For startups and innovators</h3>
                            <h1 className='font-rubik text-8xl font-bold'>$0</h1>
                            <a href="https://sandbox-integrations.switchwallet.io/create-account" target="_blank" rel="noopener noreferrer">
                                <button type='button' className='p-4 bg-white hover:bg-white w-60 md:w-64 rounded-[35.5907px] cursor-pointer mt-10'>
                                    <p className='text-gradient font-dmsans font-bold text-lg'>Get Started - For free</p>
                                </button>
                            </a>
                            <div className='space-y-5'>
                                <div className='flex gap-5'>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.11759 9.66332L11.5696 11.5023C12.237 12.0029 13.1774 11.9045 13.7268 11.2767L19.1145 5.11926" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M20.0231 11.4808C20.0231 13.1898 19.4878 14.8559 18.4924 16.2451C17.4969 17.6342 16.0913 18.6767 14.473 19.226C12.8547 19.7754 11.1049 19.804 9.46952 19.3079C7.8341 18.8118 6.39515 17.8159 5.35477 16.46C4.3144 15.1042 3.72485 13.4565 3.66893 11.7484C3.61301 10.0403 4.09354 8.35763 5.04301 6.93664C5.99249 5.51565 7.36322 4.42776 8.96269 3.82576C10.5622 3.22376 12.31 3.13789 13.9608 3.58022" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>No Hidden Cost</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.11759 9.66332L11.5696 11.5023C12.237 12.0029 13.1774 11.9045 13.7268 11.2767L19.1145 5.11926" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M20.0231 11.4808C20.0231 13.1898 19.4878 14.8559 18.4924 16.2451C17.4969 17.6342 16.0913 18.6767 14.473 19.226C12.8547 19.7754 11.1049 19.804 9.46952 19.3079C7.8341 18.8118 6.39515 17.8159 5.35477 16.46C4.3144 15.1042 3.72485 13.4565 3.66893 11.7484C3.61301 10.0403 4.09354 8.35763 5.04301 6.93664C5.99249 5.51565 7.36322 4.42776 8.96269 3.82576C10.5622 3.22376 12.31 3.13789 13.9608 3.58022" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>Wallet Report /Analytics</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.11759 9.66332L11.5696 11.5023C12.237 12.0029 13.1774 11.9045 13.7268 11.2767L19.1145 5.11926" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M20.0231 11.4808C20.0231 13.1898 19.4878 14.8559 18.4924 16.2451C17.4969 17.6342 16.0913 18.6767 14.473 19.226C12.8547 19.7754 11.1049 19.804 9.46952 19.3079C7.8341 18.8118 6.39515 17.8159 5.35477 16.46C4.3144 15.1042 3.72485 13.4565 3.66893 11.7484C3.61301 10.0403 4.09354 8.35763 5.04301 6.93664C5.99249 5.51565 7.36322 4.42776 8.96269 3.82576C10.5622 3.22376 12.31 3.13789 13.9608 3.58022" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>API Integrations</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.11759 9.66332L11.5696 11.5023C12.237 12.0029 13.1774 11.9045 13.7268 11.2767L19.1145 5.11926" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M20.0231 11.4808C20.0231 13.1898 19.4878 14.8559 18.4924 16.2451C17.4969 17.6342 16.0913 18.6767 14.473 19.226C12.8547 19.7754 11.1049 19.804 9.46952 19.3079C7.8341 18.8118 6.39515 17.8159 5.35477 16.46C4.3144 15.1042 3.72485 13.4565 3.66893 11.7484C3.61301 10.0403 4.09354 8.35763 5.04301 6.93664C5.99249 5.51565 7.36322 4.42776 8.96269 3.82576C10.5622 3.22376 12.31 3.13789 13.9608 3.58022" stroke="white" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>Wallet Account Managements</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-10 md:p-20 space-y-10 border rounded-[25.3008px]">
                            <h3 className="text-xl md:text-2xl font-semibold text-[#292929]">For enterprise and global brands</h3>
                            <h1 className='font-rubik text-6xl md:text-8xl font-bold text-gradient'>Custom</h1>
                            <Link to="/contact">
                                <button type='text' className='p-4 w-60 md:w-64 cursor-pointer rounded-[35.5907px] font-dmsans font-bold md:text-lg text-center text-lg mt-10 bg-[#2042B8] text-white'>Request a Consultation</button>
                            </Link>
                            <div className='space-y-5'>
                                <div className='flex gap-5'>
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.2753 10.0121L10.7273 11.8511C11.3947 12.3516 12.3351 12.2533 12.8845 11.6254L18.2722 5.46802" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M19.1809 11.8296C19.1809 13.5386 18.6455 15.2047 17.6501 16.5938C16.6546 17.983 15.249 19.0254 13.6307 19.5748C12.0124 20.1241 10.2627 20.1528 8.62724 19.6567C6.99182 19.1606 5.55287 18.1647 4.51249 16.8088C3.47211 15.453 2.88256 13.8053 2.82664 12.0972C2.77073 10.3891 3.25125 8.70638 4.20073 7.28539C5.1502 5.8644 6.52093 4.77651 8.1204 4.17451C9.71988 3.57251 11.4677 3.48665 13.1185 3.92897" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>No Hidden Charges</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.2753 10.0121L10.7273 11.8511C11.3947 12.3516 12.3351 12.2533 12.8845 11.6254L18.2722 5.46802" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M19.1809 11.8296C19.1809 13.5386 18.6455 15.2047 17.6501 16.5938C16.6546 17.983 15.249 19.0254 13.6307 19.5748C12.0124 20.1241 10.2627 20.1528 8.62724 19.6567C6.99182 19.1606 5.55287 18.1647 4.51249 16.8088C3.47211 15.453 2.88256 13.8053 2.82664 12.0972C2.77073 10.3891 3.25125 8.70638 4.20073 7.28539C5.1502 5.8644 6.52093 4.77651 8.1204 4.17451C9.71988 3.57251 11.4677 3.48665 13.1185 3.92897" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>Wallet Report /Analytics</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.2753 10.0121L10.7273 11.8511C11.3947 12.3516 12.3351 12.2533 12.8845 11.6254L18.2722 5.46802" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M19.1809 11.8296C19.1809 13.5386 18.6455 15.2047 17.6501 16.5938C16.6546 17.983 15.249 19.0254 13.6307 19.5748C12.0124 20.1241 10.2627 20.1528 8.62724 19.6567C6.99182 19.1606 5.55287 18.1647 4.51249 16.8088C3.47211 15.453 2.88256 13.8053 2.82664 12.0972C2.77073 10.3891 3.25125 8.70638 4.20073 7.28539C5.1502 5.8644 6.52093 4.77651 8.1204 4.17451C9.71988 3.57251 11.4677 3.48665 13.1185 3.92897" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>API Integrations</p>
                                </div>
                                <div className='flex gap-5'>
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.2753 10.0121L10.7273 11.8511C11.3947 12.3516 12.3351 12.2533 12.8845 11.6254L18.2722 5.46802" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                        <path d="M19.1809 11.8296C19.1809 13.5386 18.6455 15.2047 17.6501 16.5938C16.6546 17.983 15.249 19.0254 13.6307 19.5748C12.0124 20.1241 10.2627 20.1528 8.62724 19.6567C6.99182 19.1606 5.55287 18.1647 4.51249 16.8088C3.47211 15.453 2.88256 13.8053 2.82664 12.0972C2.77073 10.3891 3.25125 8.70638 4.20073 7.28539C5.1502 5.8644 6.52093 4.77651 8.1204 4.17451C9.71988 3.57251 11.4677 3.48665 13.1185 3.92897" stroke="#767676" stroke-width="2.53608" stroke-linecap="round" />
                                    </svg>
                                    <p className='font-dmsans font-bold text-sm md:text-[18px]'>Wallet Account Managements</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto bg-white my-10 md:my-28">
                <div className='max-w-7xl mx-auto pl-8 md:pl-4'>
                    <h1 className='font-rubik font-bold text-4xl'>More About Us</h1>
                </div>
                <div tabIndex={0} aria-label="group of cards" className="focus:outline-none mt-5 flex flex-wrap justify-center gap-10 px-4">
                    <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 rounded-3xl p-5">
                        <div className="w-10/12">
                            <h2 tabIndex={0} className="focus:outline-none text-xl text-gray-800 font-rubik font-medium">Secure Payment</h2>
                            <p tabIndex={0} className="focus:outline-none text-gray-500 font-dmsans text-lg font-medium pt-2">End-to-end payments and financial management in a single solution. Meet the right platform for making secure transactions.</p>
                        </div>
                    </div>
                    <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-5/12 rounded-3xl p-5">
                        <div className="w-10/12">
                            <h2 tabIndex={0} className="focus:outline-none text-xl text-gray-800 font-rubik font-medium">Faster Processing Time</h2>
                            <p tabIndex={0} className="focus:outline-none text-gray-500 font-dmsans text-lg font-medium pt-2">Transactions and orders done with Switch Wallet are processed promptly, so you can maximize your time  and money.</p>
                        </div>
                    </div>
                    <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-5/12 rounded-3xl p-5">
                        <div className="w-10/12">
                            <h2 tabIndex={0} className="focus:outline-none text-xl text-gray-800 font-rubik font-medium">No Hidden Charges</h2>
                            <p tabIndex={0} className="focus:outline-none text-gray-500 font-dmsans text-lg font-medium pt-2">We charge the lowest amount possible for transactions, with the major cost being the gas fees.</p>
                        </div>
                    </div>
                    <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-5/12 rounded-3xl p-5">
                        <div className="w-10/12">
                            <h2 tabIndex={0} className="focus:outline-none text-xl text-gray-800 font-rubik font-medium">No Extra Fees</h2>
                            <p tabIndex={0} className="focus:outline-none text-gray-500 font-dmsans text-lg font-medium pt-2">We offer our customers hassle-free transactions at no additional charge.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="max-w-7xl mx-auto p-5">
                <div className="rounded-3xl mb-20 flex-row bg-gradient-plus text-white flex items-center justify-center">
                    <div className="lg:w-3/5 w-full p-12">
                        <div className="md:w-2/3">
                            <h3 className="font-rubik font-bold mb-10 text-2xl md:text-5xl">Get Started Now, Scale Fast</h3>
                        </div>
                    </div>
                    <div className="lg:w-2/5 w-full lg:flex lg:flex-row hidden items-center justify-center">
                        <img src="assets/images/big_logo.svg" alt="big logo" className="h-96 w-full" />
                        <a href="https://sandbox-integrations.switchwallet.io/create-account" target="_blank" rel="noopener noreferrer" className='absolute'>
                            <button type='button' className='p-4 bg-white hover:bg-white md:w-60 w-40 rounded-full cursor-pointer'>
                                <p className='text-gradient font-dmsans font-bold text-lg'>Create Account</p>
                            </button>
                        </a>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Pricing;