import React from 'react';
import Layout from '../components/Layout';

const PrivacyTerms = () => {
    return (
        <Layout>
            <section>
                <div className='max-w-5xl mx-auto my-20 px-6 space-y-6'>
                    <div className='mb-10'>
                        <h1 className='font-rubik font-bold text-4xl md:text-5xl text-gradient text-center'>Privacy & Terms</h1>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Introduction</h5>
                        <p className='text-lg md:text-xl text-[#52575C]'>This solution and service (hereinafter referred to as “App”) is made available by Switch Wallet Limited, a company duly registered under the Companies and Allied Matters Act, Laws of the Federation of Nigeria, 1990, with registration number 1571795 and registered office address situate at Suite D7, Bethel Plaza, 36 Garden Avenue, G.R.A, Enugu State, Nigeria.</p>
                        <p className='text-lg md:text-xl mt-10 text-[#52575C]'>These Terms and Conditions must be read alongside our Privacy Policy.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>General Terms</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>These Terms and conditions constitute the entire agreement between Switch Wallet Limited and you (whether individual or corporation) in relation to your use of this App (whether Switch Wallet for Individuals or Switch Wallet for business), and it supersedes all prior agreements and understanding if any.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>These Terms and conditions constitute the entire agreement between Switch Wallet Limited and you (whether individual or corporation) in relation to your use of this App (whether Switch Wallet for Individuals or Switch Wallet for business), and it supersedes all prior agreements and understanding if any.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>If you disagree with any of the Terms and Conditions contained herein, you must halt or remove all integrations with this App and must not under any circumstances proceed to make use of this App.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Minors or people below 13 years old are not allowed to use this App.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Intellectual Property Rights</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Under the Terms and Conditions of this App, Switch Wallet Limited and/or its licensors own all the intellectual property rights associated with the contents of this App. You are granted a limited licence only for purposes of the usage of this App.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Therefore none of the App’s content may be copied, imitated or used, in whole or in part, without the prior written permission of Switch Wallet Limited or the applicable Intellectual Property owners.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Restrictions</h5>
                        <p className='text-lg md:text-xl mb-5 text-[#52575C]'>You are specifically restricted from all of the following:</p>
                        <div className='px-5 md:px-10 mx-auto'>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Publishing any of this App’s material in any other media without any prior written approval from Switch Wallet Limited.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Selling, sublicensing and/or otherwise commercialising any of this App’s material without any prior written approval from Switch Wallet Limited.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Using this App in any way that is or may be damaging to this App.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Using this App in any way that impacts negatively on other user’s access/use of this App.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Using this App in any way that is unlawful, illegal, unauthorised, or in any way that may cause harm to this App or its users.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Engaging in any form of cyber attacks aimed to disrupt the functionality of this App.</li>
                            <li className='text-lg md:text-xl mb-3 text-[#52575C]'>Disclosing your user ID and password to third parties, your ID and Password must remain confidential at all times.</li>
                        </div>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>No Warranties</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Switch Wallet Limited provides the App on an ‘as is’ and ‘as available’ basis without any promises or representations, express or implied. In particular, we do not warrant or make any representation regarding the validity, accuracy, reliability or availability of the App.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>To the fullest extent permitted by applicable laws, we hereby exclude all promises, whether express or implied, including any promises that the App is fit for purpose, of satisfactory quality, non-infringing, is free of defects, is able to operate on an uninterrupted basis, that the use of the App by you is in compliance with laws or that any information that you transmit in connection with this App will be successfully, accurately or securely transmitted.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Limitation of Liability</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>To the fullest extent permitted under applicable laws, in no event shall Switch Wallet Limited be liable to you with respect to use of this App and/or be liable to you for any direct, indirect, special or consequential damages, including without limitation to damages for loss of goodwill, loss of profit, theft or corruption of your information, the inability to use the App and any connected device failure or malfunction thereof.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>We shall not be liable even if it has been advised of the possibility of such damages, including without limitation to damages caused by error, omission, interruption, defect, failure of performance, unauthorized use, delay in operation or transmission, line failure, computer virus, worm, Trojan horse or other harm.</p>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>In the event that any applicable law does not allow the exclusion of certain promises and/or the exclusion of liability for direct, indirect, consequential or other damages, such liability arising under or in connection with these Terms and Conditions and your use of the App shall not exceed $50.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Indemnification</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>You hereby indemnify to the fullest extent Switch Wallet Limited from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising out of or in any way related to your breach of any of the provisions of these Terms and Conditions.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Severability</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>If any provision of these Terms and Conditions is found to be invalid under any applicable law, such provisions alone shall not be applicable in these presents and shall not affect or invalidate the remaining provisions contained herein.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Variation of Terms</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Switch Wallet Limited is permitted to revise these Terms and Conditions at any time as it sees fit, and by using this App, you are expected to review these Terms and Conditions on a regular basis so as to track such changes.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Assignment</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>Switch Wallet Limited is at liberty to assign, transfer, and subcontract its rights and/or obligations, in whole or in part, under these Terms and Conditions without any prior notification to you. However, you are not allowed to assign, transfer, or subcontract any or all of your rights and/or obligations under these Terms and Conditions, except with the requisite approval of Switch Wallet Limited.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Entire Agreement</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>These Terms constitute the entire agreement between Switch Wallet Limited and you in relation to your use of this App, and it supersedes all prior agreements and understanding if any.</p>
                    </div>
                    <div className=''>
                        <h5 className='mb-5 font-dmsans font-bold text-3xl text-[#25282B]'>Governing Laws & Jurisdiction</h5>
                        <p className='text-lg md:text-xl mb-10 text-[#52575C]'>These Terms and Conditions is governed by and interpreted in accordance with the laws of Enugu State of Nigeria. In the event of any dispute arising between Switch Wallet Limited and you, you agree that such dispute shall be resolved by mediation in accordance with the mediation procedures administered by Enugu State Multi Door Courthouse. Where such a body ceases to exist, either Party shall be at liberty to refer the dispute to arbitration. The arbitration shall be conducted in accordance with the provisions of the Arbitration and Conciliation Act, Chapter A18, Laws of the Federation of Nigeria, 2004.</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PrivacyTerms;